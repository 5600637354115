.box-container {
  position: absolute;
  inset: 0;
  height: 100vh;
  .box-container-overlay {
    position: absolute;
    inset: 0;
    background-color: var(--black-opacity);
    padding: 60px 13em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .box-container-no-overlay {
    height: 100%;
  }
}

.box-container-full {
  .box-container-overlay {
    padding-right: 0 !important;
    padding-left: 0 !important;
    > *  {
      position: absolute;
      inset: 0;
    }
  }
}

.box-container-light {
  .box-container-overlay {
    background-color: unset;
  }
}


/** TABLET DESIGN */

@media only screen and (max-width: 992px) {
  .box-container-overlay {
    padding: 100px 22px;
  }
}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
  .box-container {
    position: relative;
    height: auto;
    .box-container-overlay {
      position: relative;
      padding: 120px 20px;
      padding-top: 180px;
      padding-top: 5em;
      min-height: 100vh;
      > *  {
        position: unset;
      }
    }
  }
}
