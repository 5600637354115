.mobile-wrapper {
    display: flex;
    flex-direction: column;
    // gap: 60px;
    .extra-section-wrapper {
        position: relative;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}