// --------- begin react swiper custom style -----------

.swiper {
  height: 100vh;
  position: relative;
  z-index: unset;
  .swiper-wrapper {
    .swiper-slide {
      height: 100%;
    }
  }
  // &::-webkit-scrollbar {
  //     display: none;
  // }
}

// --------- end react swiper custom style -----------

.btn-move-slide {
  background: unset;
  border: unset;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  svg {
    font-size: 64px;
    color: var(--white);
  }
}

.btn-prev-slide {
  top: 20px;
}
.btn-next-slide {
  bottom: 20px;
}

.swiper-pagination {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.6em;
  font-size: 13px !important;
  position: fixed;
  right: 3em;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
  animation-name: fadeInRight;
  animation-duration: 400ms;
  .pagination-item {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1em;
    img {
      width: 1.2em;
    }
    .pagination-item-icon-circle {
      img {
        width: 0.8em;
      }
    }
  }
  .swiper-pagination-bullet {
    // color: var(--white);
    padding: 5px 16px;
    width: 90px;
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease-in-out;
    .pagination-item-label {
      position: absolute;
      right: -120px;
      white-space: nowrap;
      font-size: 13px;
    }
    .pagination-item-icon-circle {
      margin-left: auto;
    }
    // img {
    //     filter: brightness(0) invert(1);
    // }
  }

  .swiper-pagination-bullet:hover,
  .swiper-pagination-bullet-active {
    background-color: var(--white-opacity);
    width: 160px;
    .pagination-item-label {
      position: relative;
      left: 0;
      white-space: nowrap;
      transition: all 0.2s ease-in-out;
    }
  }
}

.swiper-background {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.swiper-background-blur {
  filter: blur(8px);
}

@keyframes drawAnimation {
  0% {
    opacity: 0;
    right: -50px;
    width: 0;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    right: 0;
    width: initial;
  }
}

/** TABLET DESIGN */

@media only screen and (max-width: 992px) {
  .swiper-pagination {
    display: none;
  }
}
