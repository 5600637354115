.activity-section-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    height: 100%;
    flex: 1 1;
  }
  .activity-item-container {
    height: 100%;
    position: relative;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px;
    filter: grayscale(100%);
    transition: filter 0.8s linear;
    & * {
      position: relative;
    }
    .activity-item-background-color {
      background: black;
      opacity: 0.65;
      position: absolute;
      inset: 0;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      filter: grayscale(0);
      .activity-item-background-color {
        opacity: 0.4;
      }
    }
    .activity-item-desc {
      margin: 20px auto;
      max-width: 320px;
    }
  }
}

/** TABLET DESIGN */

@media only screen and (max-width: 992px) {
  .activity-section-wrapper {
    // flex-flow: column wrap;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    a {
      width: 100%;
      h3 {
        font-size: 30px;
      }
    }
  }
}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
  .activity-section-wrapper {
    flex-flow: column wrap;
  }
  .activity-section-wrapper {
    .activity-item-container {
      height: 360px;
    }

  }
}
