.introsection-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    .home-slider-wrapper {
        z-index: 99;
        .home-slide-text-banner {
            margin-inline: auto;
            min-width: 265px;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .activity-description {
                margin-bottom: 3em;
                text-align: center;
            }
            .button-home-slide-action {
                display: flex;
                gap: 1em;
                margin-bottom: 2em;
            }
        }
        .activity-list {
            width: 80%;
            min-width: 250px;
            display: flex;
            justify-content: space-around;
            gap: 1em;
            margin-bottom: 3em;
            .activity-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8em;
                text-align: center;
                span.activity-item-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid black;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    img {
                        width: 1em;
                    }
                    .pagination-item-icon-circle {
                        width: 1em;
                    }
                }
            }
        }
    }
    .abstract {
        position: absolute;
        z-index: 1;
    }
    .abstract svg {
        width: 420px;
        height: 420px;
    }
    
    .abstract-top-right {
        top: -15px;
        right: -15px;
        svg {
            rotate: 180deg;
        }
    }
    .abstract-bottom-left {
        bottom: 0px;
        left: -15px;
    }
    
}


/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
    .introsection-container {
        .abstract svg {
            width: 320px;
            height: 320px;
        }

        .abstract-top-right {
            top: -15px;
            right: 0px;
            svg {
                rotate: 180deg;
            }
        }
        .abstract-bottom-left {
            bottom: 0px;
            left: -10px;
        }
    }
}

  