
.custom-icon {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    text-align: center;
    // .outsourcing-techno-item-icon {
    //   width: 47px;
    //   height: 46px;
    //   display: flex;
    //   justify-content: center;
    //   animation: 1em;
    //   align-items: center;
    //   border: 2px solid white;
    //   border-radius: 50%;
    //   img {
    //     filter: brightness(0) invert(1);
    //     width: 1.4em;
    //   }
    // }
    span.custom-icon-img {
        border: 2px solid white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  