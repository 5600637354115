.contact-section-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 120px;

    .contact-info-container {
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 0.5;

        h2 {
            line-height: 1em;
        }

        p.contact-info-desc {
            width: 90%;
            min-width: 219px;
            line-height: 1.5;
        }

        .contact-info-contact {
            .activity-item-icon {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .contact-form-container {
        flex: 0.4;
        height: max-content;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .form-wrapper {
            min-width: 220px;
            max-width: 700px;
            padding: 2em;

            form {
                .input-column {
                    display: flex;
                    flex-direction: column;
                    gap: 0.6em;
                }

                label {
                    font-size: 0.7em;
                    text-transform: uppercase;
                }

                .form-input-container {
                    margin-bottom: 1.2em;

                    input,
                    textarea {
                        border: 1px solid #dfccdf;
                        outline: none;
                        padding: 0.5em 1.5em;
                    }

                    textarea {
                        width: 100%;
                    }
                }

                .form-input-inline {
                    display: flex;
                    gap: 0.5em;
                }

                .form-name-company,
                .form-mail-object {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    gap: 1em;
                }

                .btn-submit-contact {
                    width: 100%;
                    padding: 0.7em;
                }
            }
        }
    }

    .contact-parteners {
        .parteners-logos {
            display: flex;
            flex-flow: row wrap;
            column-gap: 30px;
            row-gap: 15px;

            img {
                height: 48px;
            }
        }
    }


    .contact-item {
        display: flex;
        align-items: center;
        gap: 1em;
        margin-bottom: 1em;
    }
}

/** TABLET DESIGN */

@media only screen and (max-width: 992px) {
    .contact-section-wrapper {
        flex-direction: column-reverse;
        gap: 0em;

        .contact-form-container {
            margin-bottom: 5em;

            .form-wrapper {
                width: 60%;
            }
        }
    }
}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
    .contact-section-wrapper {
        gap: 120px;
        margin-top: 0;
        flex-direction: column;
        .contact-form-container {
            .form-wrapper {
                width: 100%;
            }
        }
    }
}